<template>
  <div>
    <navbar :class="{show: true}"></navbar>
    <links></links>
    <div class="flex-container">
      <div class="topics">
        <a @click="goBack"><p>&lt; {{ $t('goBack') }}</p></a>
      </div>
      <div class="main-text">
        <span v-html="site.general_terms"></span>
      </div>
    </div>
    <contact-footer :showFooter="true"/>
    <legal-footer :showFooter="true"/>
  </div>
</template>

<script>
import TitleSetter from '@/components/TitleSetter'
import Navbar from '@/components/Navbar'
import ContactFooter from '@/components/landing-page/ContactFooter'
import LegalFooter from '@/components/landing-page/LegalFooter'
import Links from '@/components/Links'

import { mapGetters } from 'vuex'

export default {
  name: 'terms',
  components: {
    TitleSetter,
    Navbar,
    ContactFooter,
    LegalFooter,
    Links
  },
  computed: {
    ...mapGetters([
      'congressName',
      'congressEmail',
      'site'
    ])
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  },
  data: function () {
    return {
      showNavbar: true,
      showFooter: true
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-container {
  min-height: calc(100vh - 357px);
  display: flex;
  justify-content: start;
  align-items: top;
  width: 100vw;
  padding-top: 30px;
  text-align: left;
}
.main-text {
  width: 50%;
  font-size: 18px;

  h3 {
    font-size: 42px;
  }
}

.topics {
  padding-left: 50px;
  width: 30%;
  p {
    margin-bottom: 10px;
    margin-top: 18px;
  }
  a {
    text-decoration: none;
    color: #2c3e50;
    font-weight: bold;
  }
}

@media (max-width: 768px) {

  .links {
    padding-top: 60px;
    padding-left: 0 !important;
    overflow: scroll;

    a {
      font-weight: bold;
      color: #747272;
      font-size: 14px !important;
    }
  }

  .flex-container {
    height: auto;
    width: auto;
    padding-top: 0;
    margin: 0 20px;
    flex-direction: column;

    .topics {
      padding-left: 0;
    }

    .main-text {
      width: 100%;

      h3 {
        font-size: 22px;
      }

      p, ul {
        font-size: 15px;
      }
    }
  }

}

</style>
